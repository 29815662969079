.header {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  width: 100vw;
  padding: 24px 5%;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  background: rgba(255, 255, 255, 0.80);
}

.logo-container img {
  height: 24px;
}

.navigation ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.navigation a {
  text-decoration: none;
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.btn {
  cursor: pointer;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 2px solid #1C38A9;
  border-radius: 8px;
  background-color: #fff;
  color: #1C38A9;
  font-family: 'Red Hat Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.btn:hover {
  background-color: #1C38A9;
  color: #fff;
}

.btn-primary {
  background-color: #1C38A9;
  color: #fff;
}

.btn-primary:hover {
  background-color: #fff;
  color: #1C38A9;
  border: 2px solid #1C38A9;
}

.header-mobile {
  display: none;
}

@media (max-width: 768px) {
  .header {
    display: none;
  }

  .header-mobile {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    width: 100%;
    padding: 24px 16px ;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: rgba(255, 255, 255, 0.80);
  }
}