.blog--image-container {
    width: 378px;
    height: 240px;
    align-self: center;
    border-radius: 16px;
}

.blog-container {
    width: auto;
    display: flex;
    max-width: 378px;
    height: 500px;
    gap: 16px;
    flex-direction: column;
}

.blog-container h1 {
    color: #3C3C3C;
    font-family: 'Red Hat Text';
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    margin: 0 !important;
    padding: 0 !important;
    max-height: 60px;
    text-overflow: clip;
    overflow: hidden;
}

.blog-container p {
    color: #606060;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Red Hat Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    max-height: 64px;
    margin: 0 !important;
    padding: 0 !important;
    white-space: wrap;
}

.blog-container button {
    cursor: pointer;
    color: #1C38A9;
    font-family: 'Red Hat Text';
    font-size: 16px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    text-align: start;
    width: auto;
}


.blogs-section {
    background-color: #F6F7FE;
    height: 700px;
    width: 100%;
    padding: 100px 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.blogs-container {
    display: flex;
    margin-top: 40px;
    gap: 48px;
    max-width: 100%;
}

.blog-badge {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 12px;
    background: #C1E5D3;
}

.blog-badge span {
    color: #115A3A;
    text-align: center;
    font-family: 'Red Hat Text';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.logos-container img {
    height: 46px;
}

.blogs-title {
    color: #061A84;
    text-align: center;
    font-family: 'Red Hat Text';
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    margin: 0 !important;
    padding: 0 !important;
}

.blogs-section-mobile {
    display: none;
}

@media (max-width: 768px) {
    .blogs-section {
        display: none;
    }

    .blogs-section-mobile {
        display: flex;
        flex-direction: column;
        padding: 0;
        background-color: #F6F7FE;
        color: #061A84;
        font-family: 'Red Hat Text';
        padding: 120px 24px;
    }

    .blogs-section-mobile h2 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin: 0;
        margin-bottom: 16px;
    }

    .blogs-section-mobile .blog--image-container {
        width: 312px;
        height: 200px;
        align-self: center;
        border-radius: 16px;
    }
}