.services-section {
  height: 700px;
  width: 100%;
  display: flex;
  background-color: #F6F7FE;
}

.services-section .content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10%;
}

.services-section .content p {
  color: #1C38A9;
  font-family: 'Nunito Sans';
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  margin: 0 !important;
  padding: 0 !important;
}

.services-section .content h2 {
  color: #1C38A9;
  font-family: 'Red Hat Text';
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 600;
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 16px !important;
  line-height: 48px;
}

.services-grid-container {
  padding: 0 10%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 28px;
}

.services-grid img {
  width: 270px;
}

.service-button {
  border-radius: 8px;
  background: #1C38A9;
  padding: 16px 32px;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 40px;
  min-width: 100px;
  max-width: 250px;

  color: #FFF;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.services-section-mobile {
  display: none;
}

@media (max-width: 768px) {
  .services-section {
    display: none;
  }

  .services-section-mobile {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #F6F7FE;
    color: #061A84;
    font-family: 'Red Hat Text';
    padding: 140px 24px;
  }

  .services-section-mobile .services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin-bottom: 24px;
  }

  .services-section-mobile .services-grid img {
    width: 150px;
  }

  .services-section-mobile p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    margin-top: 8px;
  }

  .services-section-mobile .title {
    font-family: 'Nunito Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-transform: uppercase;
    margin: 0;
  }

  .services-section-mobile h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
  }

  .services-section-mobile button {
    border-radius: 8px;
    background: #1C38A9;
    padding: 16px 32px;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 24px;
    width: 100%;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

}