.hero-policies-section {
  display: flex;
  background-color: #F6F7FE;
  /* height: 1500px; */
  width: 100%;
  overflow: hidden;
  position: relative;
}

.hero-policies-content {
  padding-top: 32px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.hero-policies-content h3 {
  color: #061A84;
  font-family: 'Nunito Sans';
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
}

.hero-policies-content h2 {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  margin: 0 !important;
}

.hero-policies-content h1 {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  margin: 0 !important;
  text-align: center;
}

.hero-policies-content p {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
}

.hero-policies-content p strong {
  font-weight: 600;
}

.hero-policies-content ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  padding-left: 32px;
}

.hero-policies-content ul li {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
  margin-bottom: 8px;
}

@media (max-width: 768px) {

  .hero-policies-content {
    padding-left: 24px;
    padding-right: 24px;
  }

  .hero-policies-content h1 {
    font-size: 24px;
    line-height: 30px;
  }

}