.footer-section {
    background-color: #F6F7FE;
    padding: 50px 10%;
    width: 100%;
    height: 645px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
}

.footer-logo {
    width: 200px;
}

.footer-social {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    margin-bottom: 40px;
}

.footer-links {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 68px;
    margin-bottom: 32px;
}

.footer-links a {
    color: #061A84;
    font-family: 'Red Hat Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
}

.footer-contact {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    /* margin-bottom: 32px; */
}

.footer-contact a {
    color: #061A84;
    font-family: 'Red Hat Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
}

.copy-right {
    color: #061A84;
    font-family: 'Red Hat Text';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.address a {
    color: #061A84;
    font-family: 'Red Hat Text';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-decoration: none!important;
}

.footer-section-mobile {
    display: none;
}

.link {
    color: #061A84;
    font-family: 'Red Hat Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
}

@media (max-width: 768px) {
    .footer-section {
        display: none;
    }

    .footer-section-mobile {
        display: flex;
        flex-direction: column;
        padding: 0;
        background-color: #FFF;
        color: #061A84;
        font-family: 'Red Hat Text';
        padding: 120px 24px;
    }

    .footer-section-mobile .footer-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        margin-top: 64px;
    }

    .footer-section-mobile .footer-links a {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-decoration: none;
    }

    .footer-contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        margin-bottom: 40px;
    }

    .footer-contact a {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration: none;
    }

    .footer-logo {
        margin-bottom: 64px;
    }

    .footer-social {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 16px;
        margin-bottom: 0;
    }

}