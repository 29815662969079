.sidebar {
    width: 100%;
    position: fixed;
    height: 100%;
    background-color: #FFF;
    box-sizing: border-box;
    z-index: 9999;

    color: #061A84;
    font-family: 'Red Hat Text';
    left: 0;
    top: 0;
    display: none;
}

.sidebar .open {
    transform: translateX(0);
}

.sidebar .closed {
    transform: translateX(-250px);
}

.sidebar .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}

.menuItem {
    text-decoration: none;
    background-color: transparent;
    padding: 0;
    text-align: left;
    color: #061A84;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border: none;
}

.sidebar .btn {
    display: flex;
    height: 48px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    border-radius: 8px;
    border: 2px solid #1C38A9;
    background: #FFF;

    color: #1C38A9;
    text-align: center;
    font-family: 'Red Hat Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.sidebar .btn:hover {
    background-color: #1C38A9;
    color: #fff;
}

.sidebar .content {
    padding: 64px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: calc(100% - 56px);
}

.sidebar .content .options {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

@media (max-width: 768px) {
    .sidebar {
        display: block;
    }

    .closed {
        transform: translateX(-100%);
    }
}