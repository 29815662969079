.whatsapp-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100px;
}

.whatsapp-button {
  background-color: #25D366;
  color: #FFF;
  border: none;
  border-radius: 50%;
  padding: 0;
  font-size: 34.5px;
  text-align: center;
  cursor: pointer;
  width: 57.5px;
  height: 57.5px;
  box-shadow: 2px 2px 3px #999;
}

.formulario-container {
  animation: expandir 3s ease-out forwards;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  bottom: 80px;
  box-shadow: 0 0 10px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 150px;
  padding: 16px;
  position: fixed;
  right: 20px;
  width: 320px;
}

.formulario-container button {
  cursor: pointer;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 2px solid #1C38A9;
  border-radius: 8px;
  background-color: #fff;
  color: #1C38A9;
  font-family: 'Red Hat Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}