.media-logos-section {
    background-color: #FFF;
    height: 250px;
    width: 100%;
    padding: 100px 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.logos-container {
    display: flex;
    margin-top: 40px;
    gap: 48px;
}

.logos-container img {
    height: 46px;
}

.media-logos-title {
    color: #061A84;
    text-align: center;
    font-family: 'Red Hat Text';
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    margin: 0 !important;
    padding: 0 !important;
}

.media-logos-section-mobile {
    display: none;
}

@media (max-width: 768px) {
    .media-logos-section {
        display: none;
    }

    .media-logos-section-mobile {
        display: flex;
        flex-direction: column;
        padding: 0;
        background-color: #FFF;
        color: #061A84;
        font-family: 'Red Hat Text';
        padding: 120px 24px;
    }

    .media-logos-section-mobile h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
    }

    .media-logos-section-mobile .logos-container {
        overflow-x: auto;
        margin-top: 16px;
        gap: 24px;
    }

    .media-logos-section-mobile .logos-container img {
        height: 24px;
    }

}