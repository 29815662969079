.case-study-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    background-color: #F6F7FE;
    height: 800px;
}

.case-study-content {
    width: 50%;
}

.case-study-content h2 {
    color: #1C38A9;
    font-family: 'Red Hat Text';
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 600;
    margin: 0 !important;
    padding: 0 !important;
    margin-bottom: 24px !important;
}

.case-study-content p {
    color: #1C38A9;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 !important;
    padding: 0 !important;
}

.case-study-content span {
    color: #1C38A9;
    font-family: 'Red Hat Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    /* text-transform: uppercase; */
    margin: 0 !important;
    padding: 0 !important;
}

.case-study-content .context,
.case-study-content .results {
    color: #1C38A9;
    font-family: 'Red Hat Text';
    font-size: 0.95rem;
    font-style: normal;
    font-weight: 400;
    margin: 0 !important;
    padding: 0 !important;
    text-transform: none;
}

.case-study-content .context span,
.case-study-content .results span {
    font-weight: 500;
}

.case-study-content .paragraph {
    color: #1C38A9;
    font-family: 'Red Hat Text';
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    margin: 0 !important;
    padding: 0 !important;
}


.case-study-content ul {
    padding: 0;
    padding-left: 32px;
    margin: 0;
    margin-bottom: 64px;
}

.case-study-content ul li {
    color: #061A84;
    font-family: 'Red Hat Text';
    font-size: 0.95rem;
    font-style: normal;
    font-weight: 400;
}

.case-study-content ul li span {
    font-weight: 500;
}

.case-study-content .subtext {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
}

.case-studio-button {
    display: flex;
    border-radius: 8px;
    background: #1C38A9;
    padding: 16px 32px;
    color: white;
    border: none;
    cursor: pointer;

    color: #FFF;
    text-align: center;
    font-family: 'Red Hat Text';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;


    margin-top: 80px;
    /* margin-bottom: 20px; */
}

.case-study-actions {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 700px;
}

.case-study-img {
    position: absolute;
    z-index: 2;
    height: 200px;
    right: 50px;
}

.case-study-bg {
    height: 600px;
    position: absolute;
    bottom: 0;
    left: -30px;
}

.case-studio-action-button {
    padding: 10px 20px;
    margin-bottom: 10px;
}

.case-study-section-mobile {
    display: none;
}

@media (max-width: 768px) {
    .case-study-section {
        display: none;
    }

    .case-study-section-mobile {
        display: flex;
        flex-direction: column;
        padding: 0;
        background-color: #F6F7FE;
        color: #061A84;
        font-family: 'Red Hat Text';
        padding: 0 24px;
        padding-top: 8px;
        padding-bottom: 56px;
    }

    .case-study-section-mobile img {
        width: 300px;
        margin: 8px 0;
    }

    .case-study-section-mobile p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
        margin-top: 8px;
    }

    .case-study-section-mobile .title {
        font-family: 'Nunito Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        text-transform: uppercase;
        margin: 0;
    }

    .case-study-section-mobile h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin: 0;
        margin-bottom: 8px;
    }

    .case-study-section-mobile .context,
    .case-study-section-mobile .results {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
    }

    .case-study-section-mobile .context span,
    .case-study-section-mobile .results span {
        font-weight: 500;
    }

    .case-study-section-mobile .subtext {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
    }

    .case-study-section-mobile button {
        border-radius: 8px;
        background: #1C38A9;
        padding: 16px 32px;
        color: white;
        border: none;
        cursor: pointer;
        margin-top: 24px;
        width: 100%;

        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

}