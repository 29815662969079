.hero-contact-section {
  display: flex;
  background-color: #F6F7FE;
  height: 1100px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.hero-contact-content {
  padding-top: 32px;
  width: 58%;
  padding-left: 50px;
}

.hero-contact-content h3 {
  color: #061A84;
  font-family: 'Nunito Sans';
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 !important;
  padding: 0 !important;
}

.hero-contact-content h1 {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  margin: 0 !important;
}

.hero-contact-content p {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.hero-contact-content ul {
  /* list-style: none; */
  padding: 0;
  margin: 20px 0;
  padding-left: 32px;
  margin-bottom: 200px;
}

.hero-contact-content ul li {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.hero-contact-form-container {
  width: 42%;
  height: 1100px;
  position: relative;
  padding: 32px;
}

.hero-contact-form {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  border-radius: 16px;
  padding: 48px;
}

.hero-contact-section-mobile {
  display: none;
}

@media (max-width: 768px) {
  .hero-contact-section {
    display: none;
  }

  .hero-contact-section-mobile {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    color: #061A84;
    font-family: 'Red Hat Text';
    padding-bottom: 24px;
  }


  .hero-section-mobile h3 {
    margin: 0 !important;
    font-family: 'Nunito Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
  }

  .hero-section-mobile h1 {
    margin: 0 !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .hero-section-mobile p {
    margin: 0;
    margin-top: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .hero-section-mobile span {
    margin: 0 !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .hero-contact-form-container {
    width: 100%;
    height: 1000px;
    position: relative;
    padding: 0;
    margin-bottom: 32px;
    margin-top: 32px;
  }
  
  .hero-contact-form {
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border-radius: 16px;
    padding: 32px;
  }
}