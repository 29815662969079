.benefits-section {
  display: flex;
  background-color: #F5F5F5;
  width: 100%;
  height: 700px;
  flex-shrink: 0;
}

.benefit {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 10%;
  padding-top: 150px;
}

.benefit .content {
  height: 410px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.benefit-subtitle,
.benefit h3 {
  color: #061A84;
  font-family: 'Nunito Sans';
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 !important;
}

.benefit-title,
.benefit h2 {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 600;
  margin: 0 !important;
}

.benefit ul {
  /* list-style: none; */
  padding: 0;
  margin: 20px 0;
}

.benefit ul li {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.benefit ul li span {
  font-weight: 500;
}

.benefit-button {
  margin: 0;
  border-radius: 8px;
  background: #1C38A9;
  padding: 16px 32px;
  color: white;
  border: none;
  cursor: pointer;
  min-width: 100px;
  max-width: 250px;

  color: #FFF;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.benefits-section-mobile {
  display: none;
}

@media (max-width: 768px) {
  .benefits-section {
    display: none;
  }

  .benefits-section-mobile {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #F5F5F5;
    color: #061A84;
    font-family: 'Red Hat Text';
  }

  .benefits-section-mobile .benefit {
    padding: 160px 24px;
    width: 100%;
  }

  .benefits-section-mobile .benefit h3 {
    font-family: 'Nunito Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-transform: uppercase;
  }

  .benefits-section-mobile .benefit h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
  }

  .benefits-section-mobile .benefit ul {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 0 24px;
  }

  .benefits-section-mobile .benefit button {
    border-radius: 8px;
    background: #1C38A9;
    padding: 16px 32px;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 24px;
    width: 100%;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

}