.testimonials-section {
    background-color: #FFF;
    height: 650px;
    width: 100%;
    padding: 100px 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.testimonials-section h2 {
    color: #061A84;
    text-align: center;
    font-family: 'Red Hat Text';
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    margin: 0 !important;
    padding: 0 !important;
    margin-bottom: 32px !important;
}

.testimonials-container {
    display: flex;
    flex-direction: row;
    gap: 64px;
}

.testimonials-container .card {
    display: flex;
    width: 288px;
    height: 293px;
    padding: 16px 16px 12px 16px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    background: #FAFAFA;
}

.testimonials-container .card .author {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 16px;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.testimonials-container .card .author .avatar {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 24px;
}

.testimonials-container .card .author .info {
    display: flex;
    flex-direction: column;
}

.testimonials-container .card .author .info .title {
    color: #061A84;
    font-family: 'Red Hat Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.testimonials-container .card .author .info .subtitle {
    color: #1C38A9;
    text-align: center;
    font-family: 'Red Hat Text';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.testimonials-container .card .text {
    color: #3C3C3C;
    font-family: 'Red Hat Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.testimonials-section-mobile {
    display: none;
}

@media (max-width: 768px) {
    .testimonials-section {
        display: none;
    }

    .testimonials-section-mobile {
        display: flex;
        flex-direction: column;
        padding: 0;
        background-color: #FFF;
        color: #061A84;
        font-family: 'Red Hat Text';
        padding: 40px 24px;
        padding-bottom: 120px;
    }

    .testimonials-section-mobile h2 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin: 0;
        margin-bottom: 16px;
    }

    .testimonials-section-mobile .testimonials-container {
        overflow-x: auto;
        gap: 16px;
    }
}