.insurance-comparator-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  background-color: #FAFAFA;
  height: 700px;
}

.comparator-content {
  max-width: 50%;
}

.comparator-content p {
  color: #1C38A9;
  font-family: 'Nunito Sans';
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  margin: 0 !important;
  padding: 0 !important;
}

.comparator-content h2 {
  color: #1C38A9;
  font-family: 'Red Hat Text';
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 600;
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 16px !important;
  line-height: 48px;
}


.comparator-button {
  border-radius: 8px;
  background: #1C38A9;
  padding: 16px 32px;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 40px;
  min-width: 100px;
  max-width: 250px;

  color: #FFF;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.comparator-image {
  padding-left: 20px;
}

.comparator-image img {
  max-width: 100%;
  height: auto;
}

.insurance-comparator-section-mobile {
  display: none;
}

@media (max-width: 768px) {
  .insurance-comparator-section {
    display: none;
  }

  .insurance-comparator-section-mobile {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #FAFAFA;
    color: #061A84;
    font-family: 'Red Hat Text';
    padding: 120px 24px;
  }

  .insurance-comparator-section-mobile img {
    width: 230px;
    margin-bottom: 24px;
  }

  .insurance-comparator-section-mobile p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    margin-top: 8px;
  }

  .insurance-comparator-section-mobile .title {
    font-family: 'Nunito Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-transform: uppercase;
    margin: 0;
  }

  .insurance-comparator-section-mobile h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
  }


  .insurance-comparator-section-mobile button {
    border-radius: 8px;
    background: #1C38A9;
    padding: 16px 32px;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 24px;
    width: 100%;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

}