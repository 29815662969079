.hero-section {
  display: flex;
  align-items: center;
  /* padding: 0 50px; */
  background-color: #F6F7FE;
  height: 1000px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.hero-content {
  width: 58%;
  padding-left: 50px;
}

.hero-image-container {
  width: 42%;
  height: 1000px;
  position: relative;
}

.hero-image-container img {
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
}

.hero-content h3 {
  color: #061A84;
  font-family: 'Nunito Sans';
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 !important;
  padding: 0 !important;
}

.hero-content h1 {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  margin: 0 !important;
}

.hero-content p {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.hero-cta-button {
  border-radius: 8px;
  background: #1C38A9;
  padding: 16px 32px;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 40px;
  /* min-width: 100px; */
  /* max-width: 250px; */

  color: #FFF;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.hero-logos {
  overflow: hidden;
  width: 50%;
}

.hero-logo-img {
  top: 185px;
  position: absolute;
  height: 600px;
  right: 0;
}

.hero-logos img {
  width: 100%;
}

.text-container {
  margin-top: 200px;
}

.text-container span {
  color: #1C38A9;
  font-size: 1.2rem;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 500;
  margin: 0 !important;
  padding: 0 !important;
}

.text-container p {
  color: #1C38A9;
  font-size: 1rem;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  margin: 0 !important;
  padding: 0 !important;
}

.hero-section-mobile {
  display: none;
}

@media (max-width: 768px) {
  .hero-section {
    display: none;
  }

  .hero-section-mobile {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    color: #061A84;
    font-family: 'Red Hat Text';
    padding-bottom: 24px;
  }

  .hero-section-mobile img {
    width: 220px;
    margin-bottom: 16px;
  }

  .hero-section-mobile button {
    color: #FFF;
    text-align: center;
    font-family: 'Red Hat Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 56px;
  }

  .hero-section-mobile h3 {
    margin: 0 !important;
    font-family: 'Nunito Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
  }

  .hero-section-mobile h1 {
    margin: 0 !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .hero-section-mobile p {
    margin: 0;
    margin-top: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .hero-section-mobile span {
    margin: 0 !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1550px) {
  .hero-content {
    width: 66%;
  }

  .hero-section {
    height: 620px;
  }

  .hero-image-container {
    height: 620px;
    width: 42%;
  }

  .hero-image-container img {
    bottom: 0;
    height: 100%;
    width: auto;
    left: 16px;
    right: auto;
  }

  .hero-content h1 {
    font-size: 3rem;
  }

  .text-container {
    margin-top: 80px;
  }
}